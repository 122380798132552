import React, { useEffect, useState } from "react";
import { databases, storage } from "/Users/bappihussayn/momentscapture/src/appwriteConfig.js"; // ✅ Import Query
import { Query } from "appwrite";
import { useAuth } from "../utils/AuthContext.js";
import "./Profile.css";
import { render } from "@testing-library/react";

const DATABASE_ID = "660a69b88bcb9308f3c7";
const COLLECTION_ID = "661142239aca7c2dbec7";
const BUCKET_ID = "660bc09a632c78a37b51";

const Profile = () => {
  const { user } = useAuth();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!user) {
      window.location.href = "/login";
      return;
    }
    fetchUserProfile();
  }, []);

  // ✅ Fetch user profile using correct Query syntax
  const fetchUserProfile = async () => {
    try {
      const response = await databases.listDocuments(DATABASE_ID, COLLECTION_ID, [
        Query.equal("userId", user.$id)
      ]);

      if (response.documents.length > 0) {
        setProfile(response.documents[0]); 
      } else {
        createUserProfile();
      }
    } catch (err) {
      setError("Error fetching profile: " + err.message);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Create a new profile if none exists
  const createUserProfile = async () => {
    try {
      const newProfile = await databases.createDocument(
        DATABASE_ID,
        COLLECTION_ID,
        "unique()",
        {
          userId: user.$id,
          name__: user?.name || "User",
          Bio: "Add Your Address...",
          phonenumber: null, // ✅ Default is NULL
          Profile_picture: null,
        }
      );

      setProfile(newProfile);
    } catch (err) {
      setError("Error creating profile: " + err.message);
      console.error(err);
    }
  };

  // Handle Profile Image Upload
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file || !profile?.$id) return;

    const allowedExtensions = ["jpg", "jpeg", "png", "webp"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      setError("File type not allowed! Please upload a jpg, png, or webp.");
      return;
    }

    try {
      const uploadedFile = await storage.createFile(BUCKET_ID, "unique()", file);
      const fileURL = storage.getFileView(BUCKET_ID, uploadedFile.$id);

      await updateProfileData({ Profile_picture: fileURL });

    } catch (error) {
      setError("File upload error: " + error.message);
      console.error("File upload error:", error);
    }
  };

  // Update profile data
  const updateProfileData = async (updates) => {
    if (!profile || !profile.$id) {
      setError("Profile ID is missing!");
      return;
    }

    try {
      const updatedProfile = await databases.updateDocument(
        DATABASE_ID,
        COLLECTION_ID,
        profile.$id,
        {
          name__: user?.name,
          email: user?.email,
          Bio: updates.Bio !== undefined ? updates.Bio : profile.Bio,
          phonenumber: updates.phonenumber !== undefined && updates.phonenumber !== "" 
            ? parseInt(updates.phonenumber, 10) 
            : profile.phonenumber, // ✅ Ensure it's an integer
          Profile_picture: updates.Profile_picture !== undefined ? updates.Profile_picture : profile.Profile_picture,
        }
      );

      setProfile(updatedProfile);
      setError(""); 
    } catch (err) {
      setError("Error updating profile: " + err.message);
      console.error("Profile update failed:", err);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;

  return (
    <div className="profile-container">
      <div className="profile-card">
        <input type="file" onChange={handleImageUpload} hidden id="fileInput" />
        <label htmlFor="fileInput">
          {profile?.Profile_picture ? (
            <img src={profile.Profile_picture} alt="Profile" className="profile-pic" />
          ) : (
            <div className="empty-profile-pic">Upload Image</div>
          )}
        </label>
        <h2 className="h2-profile">{user?.name || "User"}</h2>
        
        <div className="profile-data">
          <label>Email:</label>
          <p className="p-profile input-profile">{user?.email || "No email available"}</p>
        </div>

        <div className="profile-data">
          <label>Residential Address:</label>
          <input
            className="input-profile inf"
            type="text"
            placeholder="Update Address"
            value={profile?.Bio || ""}
            onChange={(e) => setProfile({ ...profile, Bio: e.target.value })}
          />
        </div>

        <div className="profile-data">
          <label>Phone Number:</label>
          <input
  className="input-profile inf"
  type="text"
  placeholder="Enter 10-digit Phone Number"
  value={profile?.phonenumber || ""}
  onChange={(e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length <= 10) {
      setProfile({ ...profile, phonenumber: value });
    }
  }}
  onBlur={() => {
    if (profile?.phonenumber?.length !== 10) {
      window.alert("Phone number must be exactly 10 digits.");
    }
  }}
/>


        </div>

        <button
          className="btn-profile"
          onClick={() => updateProfileData({ Bio: profile.Bio, phonenumber: profile.phonenumber })}
        >
          Save Profile
        </button>
      </div>
    </div>
  );
};

export default Profile;
