import React, { useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import Sociallogin from './Sociallogin';
import './Login.css';

const Register = () => {
    const navigate = useNavigate();
    const registerForm = useRef(null);
    const { registerUser, user } = useAuth();

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user, navigate]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const name = registerForm.current.name.value;
        const email = registerForm.current.email.value;
        const password1 = registerForm.current.password1.value;
        const password2 = registerForm.current.password2.value;

        if (password1 !== password2) {
            alert('Passwords did not match!');
            return;
        }

        const userInfo = { name, email, password1, password2 };
        registerUser(userInfo);
    };

    return (
        <div className="login-page-wrapper">
            <div className="login-container">
                <div className="login-box">
                    <h2 className="login-title">Create an Account</h2>
                    <form ref={registerForm} onSubmit={handleSubmit} className="login-form">
                        <div className="login-input-group">
                            <label>Name:</label>
                            <input type="text" name="name" placeholder="Enter name..." required />
                        </div>
                        <div className="login-input-group">
                            <label>Email:</label>
                            <input type="email" name="email" placeholder="Enter email..." required />
                        </div>
                        <div className="login-input-group">
                            <label>Password:</label>
                            <input type="password" name="password1" placeholder="Enter password..." required />
                        </div>
                        <div className="login-input-group">
                            <label>Confirm Password:</label>
                            <input type="password" name="password2" placeholder="Confirm password..." required />
                        </div>
                        <button type="submit" className="login-btn">Register</button>
                    </form>
                    <p className="login-text">
                        Already have an account? <Link to="/login" className="login-link">Login</Link>
                    </p>
                    <Sociallogin />
                </div>
            </div>
        </div>
    );
};

export default Register;
