import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Services.css";

// Use the Appwrite URL as a string, not an import
const photo1 = "https://cloud.appwrite.io/v1/storage/buckets/67d3e209001988d80b4f/files/photo1/view?project=66098019dd541f15e34c&mode=admin";

// // Import local assets correctly
 const photo2 = "https://cloud.appwrite.io/v1/storage/buckets/67d3e209001988d80b4f/files/67d3e5100029b05d83a9/view?project=66098019dd541f15e34c&mode=admin"
const video1 = "https://cloud.appwrite.io/v1/storage/buckets/67d3e209001988d80b4f/files/67d3e5a300318c70394c/view?project=66098019dd541f15e34c&mode=admin";
 const video2 = "https://cloud.appwrite.io/v1/storage/buckets/67d3e209001988d80b4f/files/67d3e583002a4cbc6851/view?project=66098019dd541f15e34c&mode=admin";
const edit1 = "https://cloud.appwrite.io/v1/storage/buckets/67d3e209001988d80b4f/files/67d3e48c000e938ec7db/view?project=66098019dd541f15e34c&mode=admin";
 const edit2 = "https://cloud.appwrite.io/v1/storage/buckets/67d3e209001988d80b4f/files/67d3e42600074bbaa154/view?project=66098019dd541f15e34c&mode=admin";

const Services = () => {
  const [showMore, setShowMore] = useState({
    photography: false,
    videography: false,
    editing: false,
  });

  const toggleMore = (type) => {
    setShowMore({ ...showMore, [type]: !showMore[type] });
  };

  return (
    <div className="services-container">
      <h2 className="services-heading">Our Services</h2>
      <p className="services-subtext">
        "Discover seamless convenience and efficiency with our tailored services. Elevate your experience effortlessly."
      </p>

      {/* Photography Section */}
      <div className="service-section">
        <h3 className="service-title">
          <Link to="/workgallery">Photography</Link>
        </h3>
        <p className="service-description">
          Unlock the magic of moments frozen in time. Every frame tells a story.
        </p>
        <div className="service-images">
          <img src={photo1} alt="Photography Sample 1" />
          <img src={photo2} alt="Photography Sample 2" />
        </div>
        <button className="toggle-button" onClick={() => toggleMore("photography")}>
          {showMore.photography ? "Show Less" : "Show More"}
        </button>
        {showMore.photography && (
          <div className="more-details">
            <h4 className="includes-title">These Includes:</h4>
            <ul className="includes-list">
              <li>
                <Link to="/workgallery">Portrait Photography</Link> – Capturing emotions and expressions beautifully.
              </li>
              <li>
                <Link to="/workgallery">Wedding Photography</Link> – Timeless memories of your special day.
              </li>
              <li>
                <Link to="/workgallery">Product Photography</Link> – Showcasing products with artistic appeal.
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Videography Section */}
      <div className="service-section">
        <h3 className="service-title">
          <Link to="/workgallery">Videography</Link>
        </h3>
        <p className="service-description">
          Cinematic storytelling that brings moments to life with movement and sound.
        </p>
        <div className="service-images">
          <video src={video1} autoPlay loop muted playsInline className="service-video"/>
          <video src={video2} autoPlay loop muted playsInline className="service-video" />
        </div>
        <button className="toggle-button" onClick={() => toggleMore("videography")}>
          {showMore.videography ? "Show Less" : "Show More"}
        </button>
        {showMore.videography && (
          <div className="more-details">
            <h4 className="includes-title">These Includes:</h4>
            <ul className="includes-list">
              <li>
                <Link to="/workgallery">Wedding Films</Link> – Cinematic wedding highlights.
              </li>
              <li>
                <Link to="/workgallery">Brand Videos</Link> – Corporate and promotional storytelling.
              </li>
              <li>
                <Link to="/workgallery">Short Films</Link> – Captivating stories in motion.
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Editing Section */}
      <div className="service-section">
        <h3 className="service-title">
          <Link to="/workgallery">Editing</Link>
        </h3>
        <p className="service-description">
          Transform raw footage into stunning visuals with expert touch-ups and enhancements.
        </p>
        <div className="service-images">
          <img src={edit1} alt="Editing Sample 1" />
          <img src={edit2} alt="Editing Sample 2" />
        </div>
        <button className="toggle-button" onClick={() => toggleMore("editing")}>
          {showMore.editing ? "Show Less" : "Show More"}
        </button>
        {showMore.editing && (
          <div className="more-details">
            <h4 className="includes-title">These Includes:</h4>
            <ul className="includes-list">
              <li>
                <Link to="/workgallery">Photo Retouching</Link> – Enhancing colors, lighting, and details.
              </li>
              <li>
                <Link to="/workgallery">Video Editing</Link> – Seamless transitions and cinematic effects.
              </li>
              <li>
                <Link to="/workgallery">Animation</Link> – Motion graphics and animated storytelling.
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Buttons */}
      <div className="bhire-container">
        <Link to="/appointus" className="bhire">
          Hire Us now!
        </Link>
      </div>
    </div>
  );
};

export default Services;
