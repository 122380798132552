import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import './Home.css';
import Cards from './Cards';
import CouponSlideshow from './coupon.jsx';
import { Link } from 'react-router-dom';
import * as FaIcons from "react-icons/fa6";
const Body = () => {
  return (
    <>
      {/* Video Background */}
      <div className="video-container">
        <video autoPlay loop muted playsInline className="background-video">
          <source src="https://cloud.appwrite.io/v1/storage/buckets/660bc09a632c78a37b51/files/67d2d366003886b51991/view?project=66098019dd541f15e34c&mode=admin" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay"></div>
        <div className="hero-text">
          <TypeAnimation
            className="type-animation"
            sequence={[
              'A skilled team of Photographers ',
              1000,
              'A talented team of Videographers ',
              1000,
              'A dedicated team of Editors ',
              1000,
              'A creative team of Freelancers ',
              1000
            ]}
            wrapper="h1"
            speed={50}
            repeat={Infinity}
          />
          <p className="sub-text">
            Turning moments into timeless stories.
          </p>
          <Link to="/appointus" className="cta-button">Book a Session</Link>
         
        </div>
        
      </div>

      {/* About Section */}
      <div className="content">
        <div className="first-content fade-in">
          <h2>About MomentsCapture</h2>
          <p>
            MomentsCapture is a celebration of life’s most beautiful memories. 
            With a dedicated team and high-end equipment, we transform ordinary snapshots into unforgettable masterpieces.
          </p>
        </div>
      </div>

      {/* Animated Cards Section */}
      <Cards />

      {/* Experience Section */}
      <div className="content second-content fade-in">
        <h2>Why Choose Us?</h2>
        <p>
          Our expertise ensures that we not only meet but exceed expectations, 
          crafting stunning visuals with precision, passion, and professionalism.
        </p>
        <div className="links-container">
          <Link to="/workgallery" className="custom-link">View Our Work</Link>
          <Link to="/services" className="custom-link">Our Services</Link>
        </div>
      </div>
      
      
      {/* Coupon Slideshow */}
      <CouponSlideshow />
      
    </>
  );
};

export default Body;
