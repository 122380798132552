import React, { useState, useEffect } from 'react';
import { databases } from '/Users/bappihussayn/momentscapture/src/appwriteConfig.js'; 
import { ID, Permission, Role } from 'appwrite';
import { useAuth } from '../../utils/AuthContext'; 
import { useNavigate } from 'react-router-dom';
import './ReviewForm.css'; 

const ReviewForm = () => {
    const { user } = useAuth(); 
    const navigate = useNavigate(); 
    const [reviewText, setReviewText] = useState('');
    const [rating, setRating] = useState(5);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (!user) {
            navigate('/login'); 
        }
    }, [user, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            setMessage('❌ You must log in to submit a review.');
            return;
        }

        try {
            await databases.createDocument(
                '660a69b88bcb9308f3c7', 
                '67d1eb210000d20f222d', 
                ID.unique(),
                {
                    userId: user.$id,
                    username: user.name, 
                    reviewText,
                    rating
                },
                [
                    Permission.read(Role.users()),  
                    Permission.write(Role.user(user.$id)), 
                    Permission.update(Role.user(user.$id)), 
                    Permission.delete(Role.user(user.$id))  
                ]
            );
            setMessage('✅ Review submitted successfully!');
            setReviewText('');
            setRating(5);
        } catch (error) {
            setMessage('❌ Error submitting review: ' + error.message);
        }
    };

    return (
        <div className="review-container">
            <h2 className="review-title">Leave a Review</h2>
            {message && <p className="message">{message}</p>}
            <form onSubmit={handleSubmit} className="review-form">
                <textarea
                    value={reviewText}
                    onChange={(e) => setReviewText(e.target.value)}
                    placeholder="Write your review..."
                    required
                />
                <select 
                    value={rating} 
                    onChange={(e) => setRating(Number(e.target.value))}
                    className="star-rating"
                >
                    <option value="1">⭐</option>
                    <option value="2">⭐⭐</option>
                    <option value="3">⭐⭐⭐</option>
                    <option value="4">⭐⭐⭐⭐</option>
                    <option value="5">⭐⭐⭐⭐⭐</option>
                </select>
                <button type="submit" className="submit-btn">Submit Review</button>
            </form>
        </div>
    );
};

export default ReviewForm;
