import React from 'react'
import { account } from '../appwriteConfig';
import * as FaIcons from "react-icons/fa6";
const Sociallogin = () => {
    const googleAuth = (e) =>{
            e.preventDefault();
            account.createOAuth2Session(
                'google',
                'https://momentscapture.in',
                'http://momentscapture.in/fail'
            )
    }

 const btnstyle ={
    border:'none',
    outline:'none',
    fontSize:'1em',
    background:'none',
 }
  return (
    <div>
      <b style={{textAlign:"center", background:'transparent'
      }}>OR</b><br/>
      <button className='button-socials' onClick={(e)=>googleAuth(e)} style={{textAlign:"center",background: 'transparent',
    color:'white',
    border:'0',
      }}><p className='sociallogin-google'style={{textAlign:"center",color:'',background:'transparent'
      }}><FaIcons.FaGoogle></FaIcons.FaGoogle>oogle</p></button>
    </div>
  )
}

export default Sociallogin
