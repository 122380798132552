import React, { useState } from "react";
import "./About.css";
import { FaLink, FaEnvelope, FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

import bappi from "/Users/bappihussayn/momentscapture/src/Components/Assests/bappinew.jpg";
import najir from "/Users/bappihussayn/momentscapture/src/Components/Assests/najirnew.JPG";
import shine from "/Users/bappihussayn/momentscapture/src/Components/Assests/shinenew2.jpeg";
import john from "/Users/bappihussayn/momentscapture/src/Components/Assests/ranjannew.jpg";
import team from "/Users/bappihussayn/momentscapture/src/Components/Assests/team.png";

const teamMembers = [
  {
    id: 1,
    name: "Bappi Hussain",
    role: "Chairman & Co-Founder",
    image: bappi,
    email: "bappi@example.com",
    social: {
      
      instagram: "https://instagram.com/bappi---",
      linkedin: "https://linkedin.com/in/bappi----",
    },
  },
  {
    id: 2,
    name: "Najir Uddin",
    role: "CEO & Co-Founder",
    image: najir,
    email: "najir@example.com",
    social: {
      instagram: "https://instagram.com/najir=--",
      linkedin: "https://linkedin.com/in/najir---",
    },
  },
  {
    id: 3,
    name: "Muhammad Shine",
    role: "President ",
    image: shine,
    email: "shine@example.com",
    social: {
      instagram: "https://instagram.com/shine---",
      linkedin: "https://linkedin.com/in/shine--",
    },
  },
  {
    id: 4,
    name: "Ranjan Kumar",
    role: "Team Lead",
    image: john,
    email: "ranjan@example.com",
    social: {
      instagram: "https://instagram.com/ranajn--",
      linkedin: "https://linkedin.com/in/ranjan----",
    },
  },
];

const About = () => {
  const [activeMember, setActiveMember] = useState(null);

  const toggleLinks = (id) => {
    setActiveMember(activeMember === id ? null : id);
  };

  return (
    <div className="about-container">
      <h2 className='about-heading'>About Us</h2>
<p className='about-text'>
  Discover MomentsCapture: where stories come to life through captivating visuals. 
  We are passionate storytellers, dedicated to freezing moments in time, 
  turning memories into timeless treasures.
</p>
      <h2>Meet Our Team</h2>
      <div className="team-container">
        {teamMembers.map((member) => (
          <div key={member.id} className="team-card">
            <img src={member.image} alt={member.name} className="team-image" />
            <div className="team-info">
              <h3>{member.name}</h3>
              <p>{member.role}</p>
              <FaLink className="link-icon" onClick={() => toggleLinks(member.id)} />

              {activeMember === member.id && (
                <div className="social-links show">
                  <p><FaEnvelope /> {member.email}</p>
                  <p><FaFacebook /> <a href={member.social.facebook} target="_blank" rel="noopener noreferrer">Facebook</a></p>
                  <p><FaInstagram /> <a href={member.social.instagram} target="_blank" rel="noopener noreferrer">Instagram</a></p>
                  <p><FaLinkedin /> <a href={member.social.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</a></p>
                </div>
              )}
            </div>
          </div>
        ))}

        {/* Join Us Card */}
        <div className="team-card">
          <img src={team} alt="Join Us" className="team-image" />
          <div className="team-info">
            <h3>Become a Member</h3>
            <p>Join Us, Grow with Us.</p>
            <p>Email: <a href="mailto:contact@momentscapture.com">contact@momentscapture.com</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
