import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { databases, account } from "/Users/bappihussayn/momentscapture/src/appwriteConfig.js"; // Ensure correct path
import "./coupon.css";

const DATABASE_ID = "660a69b88bcb9308f3c7"; // Your Appwrite Database ID
const COLLECTION_ID = "67d28500001fd41c4161"; // Your Appwrite Collection ID

function CouponSlideshow() {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState("User"); // Default name
  const navigate = useNavigate();

  useEffect(() => {
    fetchUser();
    fetchCoupons();
  }, []);

  // Fetch Logged-in User
  const fetchUser = async () => {
    try {
      const user = await account.get();
      setUserName(user.name || "User"); // Set username if available
    } catch (error) {
      console.log("No user logged in");
    }
  };

  // Fetch Coupons from Appwrite
  const fetchCoupons = async () => {
    try {
      const response = await databases.listDocuments(DATABASE_ID, COLLECTION_ID);
      setCoupons(response.documents);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching coupons:", error);
      setLoading(false);
    }
  };

  // Handle Coupon Click (Navigate to HireUs page with Coupon Code)
  const handleCouponClick = (couponCode, limitedUsers, claimed) => {
    if (claimed >= limitedUsers) {
      alert("Sorry, this coupon has already been fully claimed.");
      return;
    }

    updateCouponClaimedCount(couponCode, claimed);
    navigate(`/appointus?coupon=${couponCode}`);
  };

  // Update Coupon Claimed Count in Appwrite
  const updateCouponClaimedCount = async (couponCode, claimed) => {
    try {
      const coupon = coupons.find((c) => c.code === couponCode);
      if (coupon) {
        await databases.updateDocument(DATABASE_ID, COLLECTION_ID, coupon.$id, {
          claimed: claimed + 1,
        });
        fetchCoupons(); // Refresh coupons after update
      }
    } catch (error) {
      console.error("Error updating claimed count:", error);
    }
  };

  return (
    <div className="slideshow">
      {/* Show the User's Name with Stylish White Text */}
      <h4 className="user-greeting">Hello, <span>{userName}!</span></h4>
      <h6 className="coupon-title"> Claim Your Exclusive Coupon Now!</h6>

      {loading ? (
        <p className="loading-text">Loading coupons...</p>
      ) : (
        <div className="coupons-wrapper">
          {coupons.map((coupon) => (
            <div
              key={coupon.$id}
              className="coupon"
              onClick={() => handleCouponClick(coupon.code, coupon.limitedUsers, coupon.claimed)}
              style={{ backgroundColor: coupon.bgColor || "#1a1a1a" }}
            >
              <div className="code">{coupon.code}</div>
              <div className="c-i">
              <div className="discount"> {coupon.discount}% OFF</div>
              <div className="c-p">
              <div className="description">{coupon.description}</div>
             
              <div className="limited-users">
                 {coupon.claimed} / {coupon.limitedUsers} claimed
              </div></div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CouponSlideshow;
