import React, { useEffect, useState } from 'react';
import { databases } from '/Users/bappihussayn/momentscapture/src/appwriteConfig.js'; 
import './ReviewList.css';

const ReviewList = () => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await databases.listDocuments(
                    '660a69b88bcb9308f3c7', 
                    '67d1eb210000d20f222d'  
                );
                setReviews(response.documents);
            } catch (error) {
                console.error('Error fetching reviews:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReviews();
    }, []);

    const renderStars = (rating) => {
        return '⭐'.repeat(rating);
    };

    return (
        <div className="review-list-container">
            <h2 className="review-list-title">Clients Reviews</h2>
            {loading ? (
                <p>Loading reviews...</p>
            ) : (
                reviews.length > 0 ? (
                    <ul className="review-list">
                        {reviews.map((review) => (
                            <li key={review.$id} className="review-item">
                                <h4 className="review-username">{review.username}</h4>
                                <p className="review-text">{review.reviewText}</p>
                                <p className="review-rating">{renderStars(review.rating)}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No reviews yet. Be the first to write one!</p>
                )
            )}
        </div>
    );
};

export default ReviewList;
