import React, { useEffect, useState } from 'react';
import { useAuth } from '../utils/AuthContext.js';
import emailjs from '@emailjs/browser'; // Import EmailJS
import './Queries.css';

const Queries = () => {
  const { user } = useAuth();
  const [reviewContent, setReviewContent] = useState('');

  useEffect(() => {
    emailjs.init('qVguaIwlSNf_b0Ffw'); // Replace with your actual Public Key
  }, []);

  const reveiwclick = (event) => {
    event.preventDefault();

    if (!reviewContent.trim()) {
      alert('Please add a query before submitting.');
      return;
    }

    emailjs
      .send(
        'service_1lqm1cn', // Your Service ID
        'template_sn28clk', // Your Template ID
        {
          to_email: 'in.momentscapture@gmail.com', // Receiver's email
          from_name: user?.email || 'Anonymous',
          message: reviewContent
        }
      )
      .then(() => {
        alert('Thank you for your review. Our team will acknowledge your query soon.');
        setReviewContent(''); // Clear input field after successful submission

        // Dynamically create and append the review div
        const div = document.createElement('div');
        div.className = 'reveiw-div';

        const h6 = document.createElement('h6');
        h6.className = 'reveiw-h6';
        h6.textContent = user?.name || 'Anonymous';

        const p = document.createElement('p');
        p.className = 'reveiw-content';
        p.textContent = reviewContent;

        div.appendChild(h6);
        div.appendChild(p);

        document.querySelector('.reveiw-main').appendChild(div);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        alert('Failed to send email. Please try again.');
      });
  };

  return (
    <div className="reveiw-box">
      <div className="reveiw-main">
        <form>
          <h6>Leave your query</h6>

          <label>
            Your Name:
            <input type="text" required value={user?.name || 'Anonymous'} className="reveiw-name" readOnly />
          </label>

          <label>
            Your Query:
            <input
              type="text"
              maxLength={400}
              required
              className="reveiw-input"
              placeholder="Add your query here"
              value={reviewContent}
              onChange={(e) => setReviewContent(e.target.value)}
            />
          </label>

          <input type="submit" onClick={reveiwclick} />
        </form>
      </div>
    </div>
  );
};

export default Queries;
