import React, { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser'; // ✅ Corrected import
import './BookAppointment.css';
import { useAuth } from '../utils/AuthContext.js';

import { Link ,useNavigate} from 'react-router-dom';
const BookAppointment = () => {
  const { user } = useAuth();
  const formRef = useRef(null);

  const [couponCode, setCouponCode] = useState('');
  const [formData, setFormData] = useState({
    userid: user ? user.$id : '',
    fullName: '',
    email: '',
    phone: '',
    contactMethod: '',
    couponcode: '',
    serviceType: '',
    preferredDate: '',
    preferredTime: '',
    additionalComments: ''
  });

  useEffect(() => {
    const getUrlParameter = (name) => {
      name = name.replace(/[[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '=([^&#]*)');
      const results = regex.exec(window.location.search);
      return results ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : '';
    };
    const coupon = getUrlParameter('coupon');
    setCouponCode(coupon);
    setFormData((prev) => ({ ...prev, couponcode: coupon }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formRef.current) return;

    emailjs.sendForm(
      'service_1lqm1cn', // ✅ Correct Service ID
      'template_rkr0jfk', // ✅ Correct Template ID
      formRef.current,
      'qVguaIwlSNf_b0Ffw' // ✅ Make sure this starts with "public_"
    )
    .then((response) => {
      console.log('SUCCESS!', response);
      alert('Your appointment request has been sent successfully!.Our Team will soon Reach you');
      setFormData({
        userid: user ? user.$id : '',
        fullName: '',
        email: '',
        phone: '',
        contactMethod: '',
        couponcode: couponCode, // Keep the coupon code
        serviceType: '',
        preferredDate: '',
        preferredTime: '',
        additionalComments: '',
      });
    })
    .catch((error) => {
      console.error('EmailJS Error:', error);
      alert(`Error: ${error.text || 'Error Occured on sending the request.Try'}`+ <button><Link to='/contactus' className='custom-link'>Connect with us</Link></button>);
    });
  };

  return (
    <div className="appointment-page">
      <div className="appointment-container">
        <h2 className="appointment-title">Book an Appointment</h2>
        <form ref={formRef} onSubmit={handleSubmit} className="appointment-form">
          <div className="form-grid">
            <label className="appointment-label">
              User ID:
              <input type="text" name="userid" className="appointment-input" value={formData.userid} readOnly />
            </label>
            <label className="appointment-label">
              Full Name:
              <input type="text" name="fullName" className="appointment-input" value={formData.fullName} onChange={handleChange} required />
            </label>
            <label className="appointment-label">
              Email Address:
              <input type="email" name="email" className="appointment-input" value={formData.email} onChange={handleChange} required />
            </label>
            <label className="appointment-label">
              Phone Number:
              <input type="tel" name="phone" className="appointment-input" value={formData.phone} onChange={handleChange} required />
            </label>
            <label className="appointment-label">
              Preferred Contact Method:
              <select name="contactMethod" className="appointment-select" value={formData.contactMethod} onChange={handleChange} required>
                <option value="">Select...</option>
                <option value="email">Email</option>
                <option value="phone">Phone</option>
              </select>
            </label>
            <label className="appointment-label">
              Type of Service:
              <select name="serviceType" className="appointment-select" value={formData.serviceType} onChange={handleChange} required>
                <option value="">Select...</option>
                <option value="photography">Photography</option>
                <option value="videography">Videography</option>
                <option value="editor">Editor</option>
                <option value="freelancer">Freelancer</option>
                <option value="rent-equipment">Rent Equipment</option>
                <option value="mixed services">Combination of services</option>
              </select>
            </label>
            <label className="appointment-label">
              Coupon Code:
              <input type="text" id="coupon" name="couponcode" className="appointment-input" value={formData.couponcode} readOnly />
            </label>
            <label className="appointment-label">
              Preferred Date:
              <input type="date" name="preferredDate" className="appointment-input" value={formData.preferredDate} onChange={handleChange} required />
            </label>
            <label className="appointment-label">
              Preferred Time:
              <input type="time" name="preferredTime" className="appointment-input" value={formData.preferredTime} onChange={handleChange} required />
            </label>
          </div>
          <label className="appointment-label">
            Additional Comments:
            <textarea name="additionalComments" className="appointment-textarea" value={formData.additionalComments} onChange={handleChange} required />
          </label>
          <button type="submit" className="appointment-button">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default BookAppointment;
