import React from 'react';
import ReviewForm from '/Users/bappihussayn/momentscapture/src/Pages/Services/ReveiwForm.js';
import ReviewList from '/Users/bappihussayn/momentscapture/src/Pages/Services/ReveiwList.js';
import { useAuth } from '../../utils/AuthContext';
import './ReviewPage.css';

const ReviewPage = () => {
    const { user } = useAuth();

    return (
        <div className="review-page">
            <h1>Reviews & Feedback</h1>
            {user ? <ReviewForm /> : <p>Please log in to write a review.</p>}
            <ReviewList />
        </div>
    );
};

export default ReviewPage;
