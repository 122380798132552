import React from "react";
import * as FaIcons from "react-icons/fa6";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <p>© 2023 MomentsCapture - All rights reserved</p>
        </div>

        <div className="footer-links">
          <h6>Quick Links</h6>
          <ul>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/workgallery">Portfolio</Link></li>
            <li><Link to="/appointus">Hire Us</Link></li>
          </ul>
        </div>

        <div className="footer-socials">
          <h6>Follow Us</h6>
          <ul>
            <li><Link to="https://www.facebook.com/momentscaptureindia" target="_blank"><FaIcons.FaFacebook /></Link></li>
            <li><Link to="https://www.instagram.com/momentscapture.in/" target="_blank"><FaIcons.FaInstagram /></Link></li>
            <li><Link to="https://www.linkedin.com/in/moments-capture-57b188303" target="_blank"><FaIcons.FaLinkedinIn /></Link></li>
            <li><Link to="https://x.com/M0mentsCapture" target="_blank"><FaIcons.FaXTwitter /></Link></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>Developed & Designed by 
          Muhammad Shine
          
        </p>
      </div>
    </footer>
  );
};

export default Footer;
