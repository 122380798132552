import React from 'react';
import * as FaIcons from "react-icons/fa6";
import { Link } from 'react-router-dom';
import map from '/Users/bappihussayn/momentscapture/src/Components/Assests/a.png';
import './ConnectUs.css';

const ConnectUs = () => {
  return (
    <>
      <div className="connectus-container">
        {/* Heading Section */}
        <div className="connectus-header">
          <h2 className="connectus-title">Join Our Community: Where Connections Flourish!</h2>
          <p className="connectus-subtitle">
            "Join our inclusive community where connections spark growth, collaboration, and endless possibilities. 
            Together, we're not just building networks, but fostering meaningful relationships that propel us forward. 
            Embrace the power of connection – join us today and be part of something extraordinary!"
          </p>
        </div>

        {/* Social Media & Contact Info */}
        <div className="connectus-content">
          {/* Social Media Links */}
          <div className="connectus-section">
            <h3 className="connectus-heading">Follow Us</h3>
            <ul className="connectus-socials">
              <li><Link to="https://www.facebook.com/momentscaptureindia" target="_blank"><FaIcons.FaFacebook /></Link></li>
              <li><Link to="https://www.instagram.com/momentscapture.in/" target="_blank"><FaIcons.FaInstagram /></Link></li>
              <li><Link to="https://www.linkedin.com/in/moments-capture-57b188303" target="_blank"><FaIcons.FaLinkedinIn /></Link></li>
              <li><Link to="https://pin.it/5tC3fvP" target="_blank"><FaIcons.FaPinterest /></Link></li>
              <li><Link to="https://x.com/M0mentsCapture?t=_II4evElwuqD2xhcw3RDDQ&s=09" target="_blank"><FaIcons.FaXTwitter /></Link></li>
            </ul>
          </div>

          {/* Contact Options */}
          <div className="connectus-section">
            <h3 className="connectus-heading">Reach Us</h3>
            <ul className="connectus-contact">
              <li><Link to="https://wa.me/7291894384" target="_blank"><FaIcons.FaWhatsapp /></Link></li>
              <li><Link to="tel:+91-7291-894-384" target="_blank"><FaIcons.FaPhone /></Link></li>
              <li><Link to="mailto:in.momentscapture@gmail.com" target="_blank"><FaIcons.FaEnvelope /></Link></li>
              <li><Link to="https://t.me/momentscapture.in" target="_blank"><FaIcons.FaTelegram /></Link></li>
            </ul>
          </div>
        </div>

        {/* Location Section */}
        <div className="connectus-location">
          <h3 className="connectus-heading">Find Us on the Map</h3>
          <Link to="https://maps.app.goo.gl/7W22dNUX8zf7aiTCA" target="_blank">
            <img src={map} alt="Map Location" className="connectus-map" />
          </Link>
          <p className="connectus-address">
            Jaina Market, A Block, Chakkarpur, Sector 28, Gurugram, Haryana 122002
          </p>
        </div>
      </div>
    </>
  );
};

export default ConnectUs;
