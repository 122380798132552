import { useState ,useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as FaIcons from "react-icons/fa6";
import './Sidebar.css'
import Home from '../main/Home.jsx';
import ReviewPage from '../../Pages/Services/ReveiwPage.js';
import ConnectUs from '/Users/bappihussayn/momentscapture/src/Pages/ConnectUs.js';
import { Link ,useNavigate} from 'react-router-dom';
import { useAuth } from '../../utils/AuthContext.js';
import { useLocation } from "react-router-dom";
function Example() {
  const navigate = useNavigate()

  const {user,logoutUser} = useAuth()

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 const RefreshOnNavigation = () => {
     const location = useLocation();
   
     useEffect(() => {
       window.location.reload();
     }, [location.pathname]); // Reloads the page when path changes
   
     return null;
   };
   const handleClick = () => {
    handleClose();
    RefreshOnNavigation();
  };
  return (
    <>
      <Button variant="primary" onClick={handleShow}  id='b' className='me-2'>
      <FaIcons.FaBars className='three-menu'  />
      </Button>

      <Offcanvas show={show} onHide={handleClose} >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Moments Capture</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='offbody'>
        <ul>
        <li>
        <Link to='/'  onClick={handleClick}>Home</Link>
        </li>
        <li>
        <Link to='/about' onClick={handleClick}>About</Link>
        </li>
        <li>
        <Link to='/services' onClick={handleClick}>Services</Link>
        </li>
        <li>
        <Link to='/contactus' onClick={handleClick}>Connect with us</Link>
        </li>
        <li>
        <Link to='/workgallery' onClick={handleClick}>See Our Portfolio</Link>
        </li>
        <li>
        <Link to='/review' onClick={handleClick}>Reveiw & Feedbacks</Link>
        </li>
        <li>
        <Link to='/appointus' onClick={handleClick}>Hire us now</Link>
        </li>
        <li>
        <Link to='/queries' onClick={handleClick}>Forward your queries</Link>
        </li>
       </ul>
       {user ?(
        <>
        <Link to='/profile' onClick={handleClick}> Profile</Link><br/>
        <button onClick={logoutUser} className="btn">Logout</button></>
       ):(
        <>
        <Link className="btn" to="/login" onClick={handleClick}>Login</Link>
        <Link className="btn" to="/register" onClick={handleClick}>SignUp</Link></>
       )}
      
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Example;