import React, { useEffect, useState } from "react";
import { Client, Databases } from "appwrite";
import "./Workgallery.css";

const WorkGallery = () => {
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubcategory, setActiveSubcategory] = useState(null);

  // ✅ Initialize Appwrite
  const client = new Client()
    .setEndpoint("https://cloud.appwrite.io/v1") // Replace with your Appwrite endpoint
    .setProject("66098019dd541f15e34c");

  const databases = new Databases(client);

  useEffect(() => {
    async function fetchMedia() {
      try {
        const response = await databases.listDocuments(
          "660a69b88bcb9308f3c7", // Database ID
          "67d30e89001297bc7f90"  // Collection ID
        );

        setMedia(response.documents); // Directly use fileUrl from database
      } catch (error) {
        console.error("Error fetching media:", error);
      } finally {
        setLoading(false);
      }
      
    }

    fetchMedia();
  }, []);
  const categorizedMedia = {};
  media.forEach((item) => {
    if (!item.categoryNumber || !item.subcategoryNumber) return;

    const categoryKey = `${item.categoryNumber}`;
    const subcategoryKey = `${item.subcategoryNumber}`;

    if (!categorizedMedia[categoryKey]) categorizedMedia[categoryKey] = {};
    if (!categorizedMedia[categoryKey][subcategoryKey]) categorizedMedia[categoryKey][subcategoryKey] = [];

    categorizedMedia[categoryKey][subcategoryKey].push(item);

    
  });
  useEffect(() => {
    if (media.length > 0 && activeCategory === null) {
      const categories = Object.keys(categorizedMedia);
      if (categories.length === 1) {
        setActiveCategory(categories[0]);
      }
    }
  }, [media, categorizedMedia, activeCategory]);

  useEffect(() => {
    if (activeCategory && categorizedMedia?.[activeCategory] && activeSubcategory === null) {
      const subcategories = Object.keys(categorizedMedia[activeCategory] || {});
      if (subcategories.length === 1) {
        setActiveSubcategory(subcategories[0]);
      }
    }
  }, [activeCategory, categorizedMedia, activeSubcategory]);
  


  
  if (loading) return <p className="loading-text">Loading Work Gallery...</p>;

  // ✅ Group media by category and subcategory
  

  return (
    <div className="work-gallery">
      <div className="categories-nav">
        {Object.entries(categorizedMedia).map(([categoryKey, subcategories]) => {
          const categoryName = media.find(item => item.categoryNumber === parseInt(categoryKey))?.categoryName || "Unknown Category";
          return (
            <button 
              key={categoryKey} 
              className={`category-btn ${activeCategory === categoryKey ? "active" : ""}`}
              onClick={() => setActiveCategory(activeCategory === categoryKey ? null : categoryKey)}
            >
              {categoryName}
            </button>
          );
        })}
      </div>

      {activeCategory && (
        <div className="subcategories-nav">
          {Object.entries(categorizedMedia[activeCategory]).map(([subcategoryKey, items]) => {
            const subcategoryName = media.find(item => item.subcategoryNumber === parseInt(subcategoryKey))?.subcategoryName || "Unknown Subcategory";
            return (
              <button 
                key={subcategoryKey} 
                className={`subcategory-btn ${activeSubcategory === subcategoryKey ? "active" : ""}`}
                onClick={() => setActiveSubcategory(activeSubcategory === subcategoryKey ? null : subcategoryKey)}
              >
                {subcategoryName}
              </button>
            );
          })}
        </div>
      )}

{activeCategory && activeSubcategory && categorizedMedia[activeCategory]?.[activeSubcategory] && (
  <div className="media-display">
    <div className="media-grid">
      {categorizedMedia[activeCategory][activeSubcategory].map((item) => (
              <div key={item.$id} className="media-card">
                {item.fileUrl ? (
  item.type && item.type.toLowerCase() === "video" ? (  
    <video className="media-video" autoPlay loop muted playsInline>
      <source src={item.fileUrl} type="video/mp4" />
    </video>
  ) : (
    <img
      src={item.fileUrl}
      alt={item.title}
      className={`media-img ${item.width < item.height ? 'portrait' : ''}`}
    />
  )
) : (
  <p className="error-message">Media not available</p>
)}

                <div className="description">
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkGallery;
