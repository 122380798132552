import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import Sociallogin from './Sociallogin';
import './Login.css';

const Login = () => {
    const navigate = useNavigate();
    const { user, loginUser } = useAuth();

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user, navigate]);

    const loginForm = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = loginForm.current.email.value;
        const password = loginForm.current.password.value;
        const userInfo = { email, password };
        loginUser(userInfo);
    };

    return (
        <div className="login-page-wrapper">
            <div className="login-container">
                <div className="login-box">
                    <h2 className="login-title">Welcome Back</h2>
                    <form onSubmit={handleSubmit} ref={loginForm} className="login-form">
                        <div className="login-input-group">
                            <label>Email:</label>
                            <input type="email" name="email" placeholder="Enter email..." required />
                        </div>
                        <div className="login-input-group">
                            <label>Password:</label>
                            <input type="password" name="password" placeholder="Enter password..." required />
                        </div>
                        <button type="submit" className="login-btn">Login</button>
                    </form>
                    <p className="login-text">Don't have an account? <Link to="/register" className="login-link">Register</Link></p>
                    <Sociallogin />
                </div>
            </div>
        </div>
    );
};

export default Login;
