import React, { useEffect, useState } from 'react';
import './Navbar.css';
import Sidebar from './Sidebar.jsx';
import * as FaIcons from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import { databases } from '/Users/bappihussayn/momentscapture/src/appwriteConfig.js'; // Ensure the path is correct
import { useAuth } from '/Users/bappihussayn/momentscapture/src/utils/AuthContext.js';
import { FaUserAlt } from "react-icons/fa";
import { Query } from "appwrite";
import { BiSolidPhotoAlbum } from "react-icons/bi";
const DATABASE_ID = "660a69b88bcb9308f3c7";
const COLLECTION_ID = "661142239aca7c2dbec7";

const Navbar = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState(null);
  const [userName, setUserName] = useState("User");

  useEffect(() => {
    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  const fetchUserProfile = async () => {
    try {
      const response = await databases.listDocuments(DATABASE_ID, COLLECTION_ID, [
              Query.equal("userId", user.$id) // ✅ Correct Query syntax
            ]);
      if (response.documents.length > 0) {
        setProfilePic(response.documents[0]?.Profile_picture || null);
        setUserName(response.documents[0]?.name__ || "User");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const goToProfile = () => {
    navigate('/profile');
  };
  const goToGallery = () => {
    navigate('/workgallery'); // Create a notifications page
  };
  const goToHome = () =>{
    navigate('/')
  }

 
  return (
    <div className='main_div'>
      

      <div className='navbar'>
        {/* User Profile Section */}
        <button className='user-login' onClick={goToProfile}>
          {profilePic ? (
            <img src={profilePic} alt="Profile" className="nav-profile-pic" />
          ) : (
            <FaIcons.FaUser className='default-user-icon' />
          )}
          <span className="username">{user ? user.name : "User"}</span>
        </button>

        {/* Website Name */}
        <button className='icon-btn' onClick={goToHome}><h3 className='main-heading'>MOMENTS CAPTURE</h3></button>
        

        {/* Icons for User Actions */}
        <div className="nav-icons">
        <button className="icon-btn" onClick={goToGallery} title="Gallery">
            <BiSolidPhotoAlbum  className="nav-icon" />
          </button>
          
        <Sidebar className='sidebar icon-btn' />
          
        </div>
      </div>
    </div>
  );
};

export default Navbar;
