import './App.css'
import { useEffect } from 'react';
import Navbar from '/Users/bappihussayn/momentscapture/src/Components/Navbar/Navbar.jsx';
import Home from '../src/Components/main/Home.jsx';
import{
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import About from '/Users/bappihussayn/momentscapture/src/Pages/About.js';
import ConnectUs from '/Users/bappihussayn/momentscapture/src/Pages/ConnectUs.js';
import BookAppointment from './Pages/BookAppointment.js';
import Services from '/Users/bappihussayn/momentscapture/src/Pages/Services/Services.js';
import WorkGallery from '/Users/bappihussayn/momentscapture/src/Pages/Services/Workgallery.js';
import Queries from './Pages/Queries.js'
import Footer from './Components/Navbar/Footer.js'
import Sidebar from './Components/Navbar/Sidebar.jsx';
import Login from "/Users/bappihussayn/momentscapture/src/AppWrite/Login.js";
import Register from "/Users/bappihussayn/momentscapture/src/AppWrite/Register.js";
import Profile from "/Users/bappihussayn/momentscapture/src/AppWrite/Profile.js";
import ReviewPage from './Pages/Services/ReveiwPage.js';
import PrivateRoutes from './utils/PrivateRoute.js';
import {AuthProvider} from './utils/AuthContext.js';
function App() {


  useEffect(() => {
    const intervalId = setInterval(() => {
      document.title = 'your moments your time your studio'; // Change 'New Title' to whatever you want
      document.title = 'MomentsCapture';
    }, 3000); // Change title every 5 seconds

  }, []); 
  
  return (
   <>
   <Router>
   <AuthProvider>
     <Navbar/> 
    <Routes>
    <Route exact path='/' element={<Home/>}>
      </Route>
      <Route exact path='/about' element={<About/>}>
      </Route>
      <Route exact path='/services' element={<Services/>}>
      </Route>
      <Route exact path='/contactus' element={<ConnectUs/>}>
      </Route>
      
      <Route exact path='/workgallery' element={<WorkGallery/>}>
      </Route>
      <Route exact path='/login' element={<Login/>}>
      </Route>
      <Route exact path='/review' element={<ReviewPage/>}>
      </Route>

      <Route element={<PrivateRoutes/>}>
          <Route exact path='/profile' element={<Profile/>}>
          </Route>
          <Route exact path='/appointus' element={<BookAppointment/>}>
      </Route>
      <Route exact path='/queries' element={<Queries/>}>
      </Route>
      </Route> 

      <Route exact path='/register' element={<Register/>}>
      </Route>
    </Routes>
    <Footer/>
    </AuthProvider>
    </Router>

   </>
  );
}

export default App;
